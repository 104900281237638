@import './vars.scss';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');

@font-face {
  font-family: 'MyriadRegular';
  src: url('./MYRIADPRO-REGULAR.OTF') format('opentype');
}

@font-face {
  font-family: 'MyriadLight';
  src: url('./MyriadPro-Light.otf') format('opentype');
}

@font-face {
  font-family: 'MyriadSemibold';
  src: url('./MYRIADPRO-SEMIBOLD.OTF') format('opentype');
}

@font-face {
  font-family: 'MyriadBold';
  src: url('./MYRIADPRO-BOLD.OTF') format('opentype');
}

@font-face {
  font-family: 'MyriadBold';
  src: url('./MYRIADPRO-BOLD.OTF') format('opentype');
}

@font-face {
  font-family: 'Officina Sans Std Bold';
  src: url('./OfficinaSansStd-Bold.otf') format('opentype'); /* Adjust the path based on where you placed the font file */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Officina Sans Std';
  src: url('./ITC-Officina-Sans-Std-Book-Font.otf') format('opentype'); /* Adjust the path based on where you placed the font file */
  font-weight: normal;
  font-style: normal;
}

* {
  scrollbar-width: thin;
}

html,
body {
  margin: 0;
  left: 0;
  top: 0;
  font-size: 80%;
  background-color: #ededed;
  // overflow-x: hidden!important;
}

hr {
  border: 1px solid $light-grey;
}

@for $var from 4 to 5 {
  h#{$var} {
    line-height: 15;
    padding: 0;
    margin: 0 !important;
  }
}

hr.separate-line {
  border: 0.5px solid rgb(199, 199, 199);
}

hr.separate-rows {
  border: 0.5px solid rgb(233, 230, 230);
}

.content-container {
  display: grid;
  grid-template-rows: 1fr auto;
}

.footer {
  grid-row-start: 2;
  grid-row-end: 3;
  height: 64px;
  background: white;

  &-logo {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 20px;
  }
}

.ellipsis {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

// ::-webkit-scrollbar {
//   width: 14px;
//   height: 14px;
// }
::-webkit-scrollbar-button {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border: 2px none #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: #bdbcbc;
}

::-webkit-scrollbar-thumb:active {
  background: #bdbcbc;
}

::-webkit-scrollbar-track {
  background: #fffdfd00;
  border: 0px none #ffffff;
  border-radius: 44px;
}

::-webkit-scrollbar-track:hover {
  background: #fffdfd00;
}

::-webkit-scrollbar-track:active {
  background: #fffdfd00;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

.MuiListItem-button:hover {
  text-decoration: none !important;
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.lateral-wrapper {
  width: 220px;
  min-height: 132px;
  position: relative;
  border: 2px solid #0d47a1;
  border-left: 40px solid #0d47a1;
  padding: 15px;
  color: #ffffff;
  font-size: small;
  display: inline-block;
}

.lateral-wrapper button {
  padding: 0;
  font-size: 1rem;
  color: white;
  text-transform: uppercase;
  //letter-spacing: 3px;
  position: absolute;
  bottom: -15px;
  left: 0;
  margin-left: -30px;
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  transform-origin: 0 0;
}

.lateral-wrapper hr {
  margin-bottom: 0;
  width: 140px;
  font-size: 5rem;
  color: white;
  position: absolute;
  bottom: 0;
  left: 30px;
  margin-left: -30px;
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  transform-origin: 0 0;
}

.lateral-content {
  width: 150px;
  height: 132px;
}

.dropbtn {
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  margin-top: 14px;
  width: 100%;
  position: relative;
  display: inline-block;
}

.dropdown-content {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  text-align: center;
  border-radius: 0px 0px 5px 5px;
  transition: visibility 0s, opacity 0.5s linear;
}

/* Links inside the dropdown */
.dropdown-content a {
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-family: 'MyriadBold', sans-serif;
  font-size: 13px;
  color: #0d47a1;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #7aa9df;
  color: #ffffff;
  transition: all 0.5s ease-in-out;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  height: 100%;
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid #0d47a1;
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.grecaptcha-badge {
  visibility: hidden;
  z-index: 5;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.nav-link-responsive {
  color: #ffffff; /* Default text color */
  font-weight: bold; /* Default font weight */
  padding: 0;
  transition: color 0.3s; /* Smooth transition for hover effect */
  position: relative;
  text-decoration: none;
}

.nav-link-responsive:hover {
  color: #ffffff; /* Change this to your desired hover color */
}

.nav-link-responsive::after {
  content: '';
  position: absolute;
  top: 25%; /* Start 10% from the top */
  bottom: 25%; /* End 10% from the bottom */
  right: 0; /* Align to the right edge */
  width: 1.5px; /* Thickness of the border */
  background-color: #ffffff;
}

.nav-link-responsive-last {
  color: #ffffff !important; /* Default text color */
  font-weight: bold; /* Default font weight */
  padding: 0;
  transition: color 0.3s; /* Smooth transition for hover effect */
  position: relative;
  text-decoration: none;
}

.nav-link-responsive-last:hover {
  color: #ffffff; /* Change this to your desired hover color */
}


.pageNotFound {
    width: 100%;
    height: 100vh;
    padding: 70px 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #222222;
    box-sizing: border-box;
}

.pageNotFound__text {
    width: 30%;
    padding-left: 10%;
}
.pageNotFound__title {
    width: 100%;
    margin: 0 0 30px 0;
    font-size: 80px;
    font-weight: 500;
}

.pageNotFound__paragraph{
    width: 100%;
    font-size: 18px;
}

.pageNotFound__title, .pageNotFound__paragraph {
    width: 100%;
    color: #fff;
}

.pageNotFound__img {
    width: 70%;
    height: 100%;
    margin: 0;
    /* background-color: blue; */
}

.pageNotFound__img img {
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 1440px) {
    .pageNotFound__title {
        font-size: 50px;
    }
    .pageNotFound__paragraph{
        font-size: 15px;
    }
    .pageNotFound__img { 
        height: auto;
    }
}

@media only screen and (max-width: 1024px) {
    .pageNotFound__title {
        font-size: 50px;
    }
    .pageNotFound__paragraph{
        font-size: 15px;
    }
    .pageNotFound__img { 
        height: auto;
    }
}

@media only screen and (max-width: 800px) {
    .pageNotFound__title {
        font-size: 30px;
    }
    .pageNotFound__paragraph {
        font-size: 14px;
    }
}

@media only screen and (max-width: 600px) and (orientation : portrait) {
    .pageNotFound__title {
        font-size: 25px;
    }
}

@media only screen and (max-width: 500px) and (orientation : portrait) {
    .pageNotFound {
        padding: 90px 20px 20px 20px;
        flex-direction: column-reverse;
    }
    .pageNotFound__text {
        flex-direction: row;
        align-items: flex-start;
    }
    .pageNotFound__title {
        text-align: center;
    }
    .pageNotFound__paragraph {
        text-align: center;
    }
    .pageNotFound__text, .pageNotFound__img {
        width: 100%;
    }
}
